import React from 'react'
import { NavLink } from 'react-router-dom';
import { Registro } from '../components/base/Registro'
import logoDark from './../../assets/images/controlcost.png';
const RegistroPage = (props) => {
    return (
        <div className="auth-wrapper">
            <div className="auth-content">
                <div className="card">
                    <div className="row align-items-center text-center">
                        <div className="col-md-12">
                            <div className="card-body">
                                <img src={logoDark} alt="" className="img-fluid mb-4" />
                                <h4 className="mb-3 f-w-400">Crear Cuenta</h4>
                                <Registro {...props} />
                                <div className="text-center">
                                    <div className="saprator my-4"><span>OR</span></div>
                                    <button className="btn text-white bg-facebook mb-2 mr-2  wid-40 px-0 hei-40 rounded-circle"><i className="fab fa-facebook-f" /></button>
                                    <button className="btn text-white bg-googleplus mb-2 mr-2 wid-40 px-0 hei-40 rounded-circle"><i className="fab fa-google-plus-g" /></button>
                                    <button className="btn text-white bg-twitter mb-2  wid-40 px-0 hei-40 rounded-circle"><i className="fab fa-twitter" /></button>
                                    <p className="mb-0 text-muted"><NavLink to="/auth/login" className="f-w-400">¿Ya tengo una cuenta?</NavLink></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistroPage
