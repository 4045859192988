import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import Loader from "../App/layout/Loader";
import Login from "../App/components/base/Login";
import config from "../config";
import NoEncontradoPage from "../App/pages/NoEncontradoPage";
import ActualizarContraseniaPage from "../App/pages/ActualizarContraseniaPage";
import ResetPasswordPage from "../App/pages/ResetPasswordPage";
import UpdatePasswordPage from "../App/pages/UpdatePasswordPage";
import RegistroPage from "../App/pages/RegistroPage";
import { RegistroConfirmar } from "../App/components/base/RegistroConfirmar";
import GuiasComponent from "../App/components/guias/GuiasComponent";

const AdminLayout = Loadable({
  loader: () => import("../App/layout/AdminLayout"),
  loading: Loader,
});
const Rutas = () => {
  return (
    <BrowserRouter basename={config.basename}>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/guias" component={GuiasComponent} />
        <Route exact path="/auth/login" component={Login} />
        <Route
          exact
          path="/auth/reset-password"
          component={ResetPasswordPage}
        />
        <Route exact path="/auth/registro" component={RegistroPage} />
        <Route
          exact
          path="/admin/change-password"
          component={ActualizarContraseniaPage}
        />
        <Route
          exact
          path="/auth/update-password/:id"
          component={UpdatePasswordPage}
        />
        <Route exact path="/auth/confirmar/:id" component={RegistroConfirmar} />
        <Route path="/base" component={AdminLayout} />
        <Route component={NoEncontradoPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default Rutas;
