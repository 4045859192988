import React, { useEffect, useState } from "react";
import Aux from "../../../hoc/_Aux";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond/dist/filepond.min.css";
import callApi from "../../../helpers/conectorApi";
import Loading from "../base/Loading";
import { OrganizarComponet } from "./OrganizarComponet";

// Registrar los plugins necesarios para la validación de tipo y tamaño de archivo
registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const fileToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
const GuiasComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [organizar, setOrganizar] = useState(false);
  const [abrirModal, setAbrirModal] = useState(false);
  const acceptedFileTypes = ["application/pdf"];
  const [listFiles, setListFiles] = useState([]);
  const [listDocuments, setListDocuments] = useState([]);

  const openBase64ImageInNewTab = (base64Data) => {
    try {
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const mimeString = "application/pdf";
      const blob = new Blob([byteArray], { type: mimeString });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    } catch (error) {
      console.error("Error al procesar la imagen base64:", error);
    }
  };

  const handleSend = () => {
    if (organizar === true) {
      setAbrirModal(true);
    } else {
      handleGetPdf(listDocuments);
    }
  };
  const handleGetPdf = async (list) => {
    setAbrirModal(false);
    let request = {};
    let listPdfs = [];
    request.listPdfs = list;
    setIsLoading(true);
    let response = await callApi("guias", {
      method: "POST",
      body: JSON.stringify(request),
    });
    setIsLoading(false);
    if (response) {
      setOrganizar(false);
      setAbrirModal(false);
      setListDocuments([]);
      setListFiles([]);
      openBase64ImageInNewTab(response);
    }
  };
  useEffect(() => {
    const processFiles = async () => {
      const documentos = await Promise.all(
        listFiles.map(async (fileItem) => {
          const fileType = fileItem.file.type;
          let fileBin = await fileToBase64(fileItem.file);
          fileBin = String(fileBin).replace("data:application/pdf;base64,", "");
          return { pdf: fileBin, fileType: fileType, isEmpty: 0 };
        })
      );
      setListDocuments(documentos);
    };

    processFiles();
  }, [listFiles]);

  return (
    <Aux>
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="card">
            {isLoading === true && <Loading />}
            {isLoading === false && (
              <div className="row align-items-center text-center">
                <div className="col-md-12">
                  <div className="card-body">
                    <FilePond
                      maxFileSize="5MB"
                      acceptedFileTypes={acceptedFileTypes}
                      className="mt-3 mb-5 mt-lg-0"
                      files={listFiles}
                      allowReorder={false}
                      allowMultiple={true}
                      onupdatefiles={setListFiles}
                      labelIdle="Arraste los archivos aqui"
                      credits={false}
                    />
                  </div>
                  <div className="form-group text-left mt-2">
                    <div className="checkbox checkbox-primary d-inline">
                      <input
                        type="checkbox"
                        name="checkbox-p-1"
                        id="checkbox-p-1"
                        checked={organizar}
                        onChange={() => {
                          setOrganizar(!organizar);
                        }}
                      />
                      <label htmlFor="checkbox-p-1" className="cr">
                        &nbsp;&nbsp;Organizar
                      </label>
                    </div>
                  </div>
                  <button
                    className="btn btn-block btn-primary mb-4"
                    disabled={listFiles.length > 0 ? false : true}
                    onClick={() => {
                      handleSend();
                    }}
                  >
                    Obtener Pdf
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {abrirModal === true && (
        <OrganizarComponet
          setAbrirModal={setAbrirModal}
          abrirModal={abrirModal}
          handleGetPdf={handleGetPdf}
          list={listDocuments}
        />
      )}
    </Aux>
  );
};

export default GuiasComponent;
