import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Modal, Table } from "react-bootstrap";
import { useForm } from "../../hooks/useForm";
import { alert_warning } from "../../../helpers/Notificacion";
import { v4 as uuidv4 } from "uuid";
export const OrganizarComponet = ({
  list = [],
  abrirModal,
  setAbrirModal,
  handleGetPdf,
}) => {
  let guiasPorHoja = 6;
  const [listDocuments, setListDocuments] = useState(list);
  const [listHojas, setListHojas] = useState([]);
  const [auxRender, setAuxRender] = useState(uuidv4());
  const [listFilasHoja, setListFilasHoja] = useState([1, 2, 3]);
  const [listItemSend, setListItemSend] = useState([]);
  const [values, handleOnChange, , setValues] = useForm({
    numHojas: 0,
  });
  console.log({ list, listDocuments });
  const handleItemCheckt = (hoja, fila, columna) => {
    let id = `id${hoja}${fila}${columna}`;
    let rsp = false;
    let exist = listItemSend.find((item) => String(item.id) === String(id));
    if (exist) {
      let { checked } = exist;
      rsp = Boolean(checked);
    }
    return rsp;
  };

  const handleCalculate = () => {
    if (listDocuments) {
      let total = listDocuments.length;
      let totalHojas = 0;
      if (Number(total) > 0) {
        totalHojas = Number(
          Number(Number(total) / Number(guiasPorHoja)).toFixed(0)
        );
      }
      if (totalHojas === 0) {
        totalHojas = 1;
      }
      setValues({ ...values, numHojas: totalHojas });
    }
  };
  const handleCheck = (hoja, fila, columna) => {
    let id = `id${hoja}${fila}${columna}`;
    let newList = [...listItemSend];
    let exist = newList.find((item) => String(item.id) === String(id));
    if (exist) {
      newList = newList.filter((item) => String(item.id) !== String(id));
    } else {
      newList.push({
        id,
        hoja,
        fila,
        columna,
        checked: true,
      });
    }
    let totalEntrantes = listDocuments.length;
    let totalSeleccionados = newList.length;
    if (totalSeleccionados <= totalEntrantes) {
      console.log("se agrego");
      setListItemSend(newList);
      setAuxRender(uuidv4());
    } else {
      alert_warning("Ya selecciono todas las casillas validas");
    }
  };

  const handlePreProcesar = () => {
    let listRequest = [];
    let totalEntrantes = listDocuments.length;
    let totalSeleccionados = listItemSend.length;

    if (totalEntrantes === totalSeleccionados) {
      const hojas = listItemSend.map((item) => item.hoja);
      const maxHoja = Math.max(...hojas);
      for (let hojaId = 1; hojaId <= maxHoja; hojaId++) {
        for (let filaId = 1; filaId <= 3; filaId++) {
          for (let columnaId = 1; columnaId <= 2; columnaId++) {
            let addImagen = handleItemCheckt(hojaId, filaId, columnaId);
            if (addImagen === true) {
              let itemPdf = listDocuments.shift();
              let { pdf } = itemPdf;
              listRequest.push({
                isEmpty: 0,
                pdf: pdf,
                hojaId,
                filaId,
                columnaId,
              });
            } else {
              listRequest.push({
                isEmpty: 1,
                pdf: "",
                hojaId,
                filaId,
                columnaId,
              });
            }
          }
        }
      }
      handleGetPdf(listRequest);
    } else {
      alert_warning("Faltan casillas por seleccionar");
    }

    console.log({ listRequest });
  };

  useEffect(() => {
    handleCalculate();
  }, [listDocuments]);

  useEffect(() => {
    if (Number(values.numHojas) > listDocuments.length) {
      alert_warning("El numero no debe de ser mayor a " + listDocuments.length);
      handleCalculate();
    } else {
      let newList = [];
      for (let index = 1; index <= values.numHojas; index++) {
        newList.push(index);
      }
      setListHojas(newList);
    }
  }, [values.numHojas]);

  //   useEffect(() => {
  //     let newList = [];
  //     for (let index = 1; index <= 5; index++) {
  //       newList.push(index);
  //     }
  //     setListHojas(newList);
  //   }, []);
  return (
    <Modal
      show={abrirModal}
      onHide={() => setAbrirModal(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">Organizar Posicion de las Guias</Modal.Title>
      </Modal.Header>
      <Modal.Body key={auxRender}>
        <div className="form-row">
          <div className="form-group col-md-12">
            <div className="form-group col-md-12">
              <label className="form-label" htmlFor="monto">
                Numero de Hojas
              </label>
              <input
                className="form-control"
                name="numHojas"
                id="numHojas"
                required=""
                value={values.numHojas}
                placeholder="Numero de Hojas"
                autoComplete="off"
                onChange={handleOnChange}
                type="number"
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <Alert variant="info">
              Se agregará una guia en cada casilla seleccionada
            </Alert>
          </div>
        </div>
        {listHojas.map((hojaId, index) => {
          return (
            <div className="form-row" key={`hojaId${index}`}>
              <div className="form-group col-md-12">
                <div className="form-group col-md-12">
                  <label className="form-label">Hoja No. {hojaId}</label>
                  <Table key={`hojaNo${index + 1}`}>
                    <thead>
                      <tr>
                        <th className="text-center">Columna 1</th>
                        <th className="text-center">Columna 2</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listFilasHoja.map((itemFila, index2) => {
                        return (
                          <tr key={`fila${index2}`}>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                name="checkbox-p-1"
                                id="checkbox-p-1"
                                checked={handleItemCheckt(hojaId, itemFila, 1)}
                                onChange={() => {
                                  handleCheck(hojaId, itemFila, 1);
                                }}
                              />
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                name="checkbox-p-1"
                                id="checkbox-p-1"
                                checked={handleItemCheckt(hojaId, itemFila, 2)}
                                onChange={() => {
                                  handleCheck(hojaId, itemFila, 2);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <div className="col-sm-3"></div>
        <div className="col-sm-3">
          <button
            type="button"
            onClick={() => {
              setAbrirModal(false);
            }}
            className="btn btn-warning"
          >
            {" "}
            Cancelar
          </button>
        </div>
        <div className="col-sm-4">
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              handlePreProcesar();
            }}
          >
            Obtener Pdf
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
