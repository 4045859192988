import React, { useState, useEffect } from 'react'
import Aux from '../../../hoc/_Aux'
import logoDark from './../../../assets/images/auth/auth-logo-dark.png'
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Col, Form } from 'react-bootstrap';
import { alert_exitoso, alert_warning } from '../../../helpers/Notificacion';
import { useForm } from '../../hooks/useForm';
import callApi from '../../../helpers/conectorApi';
import Loading from './Loading';
import { NoAutorizado } from './NoAutorizado';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
export const RegistroConfirmar = ({ match, history }) => {
    let { id } = match.params;
    const [loading, setLoading] = useState(false)

    const handleConfirmar = async () => {
        setLoading(true);
        let response = await callApi('ctl/registro', {
            method: 'PUT',
            body: JSON.stringify({ id })
        });
        if (response) {
            const MySwal = withReactContent(Swal);
            MySwal.fire({
                title: 'Información?',
                text: response,
                type: 'success',
                showCloseButton: false,
                showCancelButton: false
            }).then(async (willDelete) => {
                history.replace("/auth/login");
            });
        }
        setLoading(false);
    }
    useEffect(() => {
        handleConfirmar();
    }, []);

    return (
        <Aux>
            <div className="auth-wrapper">
                <div className="auth-content">
                    <div className="card">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12">
                                <div className="card-body">
                                    {
                                        loading === true ?
                                            <Loading />
                                            : <NoAutorizado />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Aux>
    )
}
